import React from 'react';

import CookieConsentLink from '@components/Layout/Footer/atoms/CookieConsentLink';
import Star from 'public/static/premium/star.svg';
import { langs } from 'types/lang';

export interface SubMenuItem {
  label: string;
  link: string;
  classNames?: string;
}

export const categories = ['regular', 'advertise', 'mid', 'footer', 'none'] as const;

export type MenuItemCategory = typeof categories[number];

export type MenuItem = {
  label: string;
  categoryName?: string;
  icon?: React.FC;
  color?: string;
  pill?: {
    order: number;
    color?: string;
    textColor?: string;
  }
  visibleInLang: Array<keyof typeof langs | string>;
  onDesktop: boolean;
  category: MenuItemCategory;
  onMobile: boolean;
  link: string;
  classNames?: string;
  subMenu?: SubMenuItem[];
  Component?: React.FC;
}

export const getMenuItems = (
  ...additionalFilters: ((item: MenuItem) => boolean)[]
): MenuItem[] => {
  const menuMap: MenuItem[] = [
    {
      icon: Star,
      label: 'premium',
      categoryName: 'premium',
      visibleInLang: ['pl'],
      category: 'regular',
      onDesktop: false,
      onMobile: true,
      pill: {
        order: 4,
        color: 'orange-500',
        textColor: 'white',
      },
      link: 'link.premium',
      classNames: 'flex gap-1 w-full items-center mb-3 text-orange-dim',
    },
    {
      label: 'shipment',
      visibleInLang: ['pl'],
      category: 'regular',
      categoryName: 'forwarders',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 1
      },
      link: 'link.shipment',
      classNames: '',
    },
    {
      label: 'transport',
      visibleInLang: ['pl', 'en', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      category: 'regular',
      categoryName: 'transport',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 2
      },
      link: 'link.transport',
      classNames: '',
    },
    {
      label: 'logistics',
      visibleInLang: ['pl', 'en', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      category: 'regular',
      categoryName: 'logistics',
      onDesktop: true,
      onMobile: true,
      pill: {
        order: 3
      },
      link: 'link.logistics',
      classNames: '',
    },
    /*
    {
      label: 'trucking',
      visibleInLang: ['pl', 'en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      category: 'regular',
      categoryName: 'trucking',
      onDesktop: true,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
    },
    */
    {
      label: 'trucking',
      visibleInLang: ['en', 'hu', 'lt', 'ro', 'ru', 'de', 'ua'],
      category: 'regular',
      categoryName: 'trucking',
      onDesktop: false,
      onMobile: false,
      link: 'link.trucking',
      classNames: '',
      pill: {
        order: 3
      },
    },
    {
      label: 'economy',
      visibleInLang: ['de'],
      categoryName: 'economy',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.economy',
      classNames: '',
    },
    {
      label: 'analitics',
      visibleInLang: ['ua'],
      categoryName: 'analitics',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.analitics',
      classNames: '',
    },
    {
      label: 'interviews',
      visibleInLang: ['ua'],
      categoryName: 'interviews',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'interviews',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      categoryName: 'interviews',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.interviews',
      classNames: '',
    },
    {
      label: 'magazine',
      visibleInLang: ['de', 'lt'],
      categoryName: 'magazine',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.magazine',
      classNames: '',
    },
    {
      label: 'webinars',
      visibleInLang: ['pl'],
      categoryName: 'webinars',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.webinars',
      classNames: '',
    },
    {
      label: 'startups',
      visibleInLang: ['de', 'ru', 'lt'],
      categoryName: 'startups',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.startups',
      classNames: '',
    },
    {
      label: 'video',
      visibleInLang: ['pl', 'lt', 'de', 'ua'],
      categoryName: 'video',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.video',
      classNames: '',
    },
    {
      label: 'technology',
      visibleInLang: ['ru', 'lt', 'ua'],
      categoryName: 'technology',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.technology',
      classNames: '',
    },
    {
      label: 'authors',
      visibleInLang: ['en', 'ro', 'lt', 'ru', 'ua'],
      categoryName: 'authors',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.authors',
      classNames: '',
    },
    {
      label: 'opinion',
      visibleInLang: ['de'],
      categoryName: 'opinions',
      category: 'regular',
      onDesktop: true,
      onMobile: true,
      link: 'link.opinion',
      classNames: '',
    },
    {
      label: 'advertise',
      visibleInLang: ['de', 'hu', 'lt', 'ro', 'ru'],
      category: 'advertise',
      onDesktop: true,
      onMobile: true,
      link: 'link.advertise',
      classNames: 'font-bold',
    },
    {
      label: 'advertise',
      visibleInLang: ['pl', 'en'],
      category: 'advertise',
      onDesktop: true,
      onMobile: false,
      link: 'link.advertise',
      classNames: 'font-bold',
      subMenu: [
        {
          label: 'advertiseWebinar',
          link: 'link.advertiseWebinar',
        },
        {
          label: 'advertiseArticle',
          link: 'link.advertiseArticle',
        },
      ],
    },
    {
      label: 'advertiseWebinar',
      link: 'link.advertiseWebinar',
      category: 'advertise',
      visibleInLang: ['pl', 'en'],
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'advertiseArticle',
      link: 'link.advertiseArticle',
      visibleInLang: ['pl', 'en'],
      category: 'advertise',
      onDesktop: false,
      onMobile: true,
      classNames: 'menu-list-catchy-gradient',
    },
    {
      label: 'hot',
      link: 'link.hot',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      category: 'mid',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'contact',
      link: 'link.contact',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu'],
      category: 'mid',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletter',
      link: 'link.newsletter',
      visibleInLang: ['pl', 'en', 'de', 'ua', 'lt', 'ru',],
      category: 'mid',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'newsletterAd',
      link: 'link.newsletterAd',
      visibleInLang: ['pl', 'en'],
      category: 'advertise',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'impressum',
      link: 'link.impressum',
      visibleInLang: ['de'],
      category: 'footer',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'policy',
      link: 'link.policy',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      category: 'footer',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: 'rules',
      link: 'link.rules',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      category: 'footer',
      onDesktop: false,
      onMobile: true,
      classNames: '',
    },
    {
      label: '',
      link: '',
      visibleInLang: ['pl', 'en', 'de', 'lt', 'hu', 'ro', 'ru'],
      category: 'footer',
      onDesktop: false,
      onMobile: true,
      classNames: '',
      Component: CookieConsentLink,
    },
    {
      label: 'podcast',
      link: 'menu.podcast',
      categoryName: 'podcast',
      visibleInLang: ['pl', 'en', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'],
      category: 'none',
      onDesktop: false,
      onMobile: false,
    },
  ];

  return menuMap.filter((obj) => {
    return additionalFilters.map((filter) => filter(obj)).every((result) => result);
  });
};
